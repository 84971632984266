const RESOURCES = {
  PROJECT: 'project',
  USER: 'user',
  DASHBOARD: 'dashboard',
  TAG: 'tag',
  SCRIPT: 'script',
  NOTIFICATION: 'notification',
  HISTORY: 'history'
}

const wrapActions = keys =>
  keys.reduce((acc, key, index) => {
    acc[key] = index
    return acc
  }, {})
const LEVELS = {
  PROJECT: wrapActions([
    'NONE',
    'UPDATE'
  ]),
  USER: wrapActions([
    'NONE',
    'READ',
    'UPDATE_USER',
    'UPDATE_GROUP'
  ]),
  DASHBOARD: wrapActions([
    'NONE',
    'READ',
    'INTERACTION',
    'UPDATE'
  ]),
  TAG: wrapActions([
    'NONE',
    'READ',
    'UPDATE_TAG',
    'UPDATE_TAG_TEMPLATE'
  ]),
  SCRIPT: wrapActions([
    'NONE',
    'READ',
    'UPDATE'
  ]),
  NOTIFICATION: wrapActions([
    'NONE',
    'READ',
    'EXPORT',
    'UPDATE_TEMPLATES',
    'UPDATE_SYSTEM_TEMPLATES'
  ]),
  HISTORY: wrapActions([
    'NONE',
    'READ',
    'EXPORT'
  ])
}

export default {
  RESOURCES,
  LEVELS
}
