import ym from 'ym'

const counter = process.env.YA_METRICS_KEY

function sendEvent(target, params) {
  ym(counter, 'reachGoal', target, params)
}

export {
  sendEvent
}
