<template>
  <div :class="[$style.root, `type--${type}`]">
    <div :class="$style.body">
      <AppIcon
        :name="STATUS_ICON[type]"
        :width="20"
        :height="20"
        :preload="[STATUS_ICON.success, STATUS_ICON.error]"
      />

      <div :class="$style.content">
        {{ msg }}
      </div>
    </div>
  </div>
</template>

<script setup>
import AppIcon from '@/components/icon'

const STATUS_ICON = {
  success: 'check-circle',
  error: 'status-circle'
}

defineProps({
  type: String,
  msg: String
})
</script>

<style lang="scss" module>
.root {
  padding: 15px;
  border: 1px solid transparent;
  border-radius: 6px;
  box-shadow: 0 12px 16px -4px rgba(0, 0, 0, 0.08), 0px 4px 6px -2px rgba(0, 0, 0, 0.03);
  color: var(--color-text-primary);

  & + & {
    margin-top: 18px;
  }

  &:global(.type--success) {
    border-color: var(--color-states-success);
    background-color: var(--color-surface-success);
  }

  &:global(.type--error) {
    border-color: var(--color-states-error);
    background-color: var(--color-surface-error);
  }
}

.body {
  display: flex;
}

.content {
  font-weight: 500;
  font-size: 13px;
  line-height: 20px;
  white-space: pre-line;
  margin-left: 10px;
}
</style>
