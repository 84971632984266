import { ref } from 'vue'
import { useI18n } from 'vue-i18n'

import { dashboardVersions } from '@/api'
import { API } from '@/common/constants'
import { useNotifications } from '@/stores'

export default function () {
  const { t: $t } = useI18n({ useScope: 'global' })
  const notificationsStore = useNotifications()

  const loadingVersion = ref()
  const loadingVersions = ref()
  const processingVersion = ref()
  let versionController = new AbortController()

  async function getDashboardVersions(dashboardId) {
    const data = {}
    loadingVersions.value = true
    try {
      const response = await dashboardVersions.getDashboardVersions(dashboardId)
      data.response = response.versions
    } catch (e) {
      data.error = e
      notificationsStore.showNotification('error', $t('api.dashboardVersions.http.getDashboardVersions.error'))
      console.error('dashboards history download error')
    } finally {
      loadingVersions.value = false
    }

    return data
  }

  async function getDashboardLastDetailVersions(dashboardId) {
    const data = {}
    loadingVersion.value = true
    try {
      const response = await dashboardVersions.getDashboardLastDetailVersion(dashboardId)
      data.response = response.version
    } catch (e) {
      data.error = e
      notificationsStore.showNotification('error', $t('api.dashboardVersions.http.getDashboardLastDetailVersions.error'))
      console.error('dashboards history download error')
    } finally {
      loadingVersion.value = false
    }

    return data
  }

  async function getDashboardVersion(versionId) {
    if (loadingVersion.value) {
      cancelWidgetsRequests()
    }

    const data = {}
    loadingVersion.value = true
    let isAborted = false
    try {
      const response = await dashboardVersions.getDashboardVersion(versionController.signal)(versionId)
      data.response = response.version
    } catch (e) {
      if (!e.isAborted) {
        data.error = e
        notificationsStore.showNotification('error', $t('api.dashboardVersions.http.getDashboardVersion.error'))
        console.error('dashboards version download error')
      } else {
        isAborted = true
      }
    } finally {
      if (!isAborted) {
        loadingVersion.value = false
      }
    }

    return data
  }

  async function createDashboardVersion(dashboardId, { title, widgets }) {
    const data = {}
    processingVersion.value = true
    try {
      const response = await dashboardVersions.createDashboardVersion(dashboardId, { title, widgets })
      data.response = response.version
      notificationsStore.showNotification('success', $t('api.dashboardVersions.http.createDashboardVersion.success'))
    } catch (e) {
      data.error = e
      if (e.code === API.COMMON_ERRORS.VALIDATION_FIELDS_ERROR) {
        notificationsStore.showNotification('error', $t('api.dashboardVersions.http.createDashboardVersion.errorValidation'))
      } else {
        notificationsStore.showNotification('error', $t('api.dashboardVersions.http.createDashboardVersion.error'))
      }
      console.error('version dashboard delete operation error')
    } finally {
      processingVersion.value = false
    }

    return data
  }

  async function deleteDashboardVersion(versionId) {
    const data = {}
    processingVersion.value = true
    try {
      const response = await dashboardVersions.deleteDashboardVersion(versionId)
      data.response = response.versions
    } catch (e) {
      data.error = e
      notificationsStore.showNotification('error', $t('api.dashboardVersions.http.deleteDashboardVersion.error'))
      console.error('version dashboard delete operation error')
    } finally {
      processingVersion.value = false
    }

    return data
  }

  function cancelWidgetsRequests() {
    versionController.abort()
    versionController = new AbortController()
  }

  return {
    loadingVersion,
    loadingVersions,
    processingVersion,
    getDashboardVersions,
    getDashboardVersion,
    getDashboardLastDetailVersions,
    createDashboardVersion,
    deleteDashboardVersion
  }
}
