import { ref } from 'vue'
import { useRoute } from 'vue-router'
import { useI18n } from 'vue-i18n'

import { scripts } from '@/api'
import { useNotifications } from '@/stores'
import { API } from '@/common/constants'

export default function () {
  const { t: $t } = useI18n({ useScope: 'global' })
  const $route = useRoute()
  const notificationsStore = useNotifications()

  const loading = ref(false)
  const processing = ref(false)
  const processingRun = ref(false)
  const processingExport = ref(false)

  async function getScriptInfo(scriptId) {
    let data = {}
    loading.value = true
    try {
      const response = await scripts.getScriptInfo(scriptId)
      data.response = response.script
    } catch (e) {
      data.error = e
      console.error('get script info error', e)
      notificationsStore.showNotification('error', $t('api.scripts.http.getScriptInfo.error'))
    } finally {
      loading.value = false
    }

    return data
  }

  async function createScript({ title, code, scriptNodeId, active, launchTrigger }) {
    const data = {}
    processing.value = true
    try {
      const response = await scripts.createScript({
        title,
        code,
        projectId: $route.params.projectId,
        scriptNodeId,
        active,
        launchTrigger
      })
      data.response = response.script
      notificationsStore.showNotification('success', $t('api.scripts.http.createScript.success'))
    } catch (e) {
      data.error = e
      console.error('add script error', e)
      if (e.code === API.COMMON_ERRORS.VALIDATION_FIELDS_ERROR && e.data.fieldErrors.title === 'DUPLICATE') {
        notificationsStore.showNotification('error', $t('api.scripts.http.createScript.duplicateTitleError'))
      } else {
        notificationsStore.showNotification('error', $t('api.scripts.http.createScript.error'))
      }
    } finally {
      processing.value = false
    }

    return data
  }

  async function editScript(params) {
    const data = {}
    processing.value = true
    try {
      const response = await scripts.editScript(params)
      data.response = response.script
      notificationsStore.showNotification('success', $t('api.scripts.http.editScript.success'))
    } catch (e) {
      data.error = e
      console.error('edit script error', e)
      if (e.code === API.COMMON_ERRORS.VALIDATION_FIELDS_ERROR && e.data.fieldErrors.title === 'DUPLICATE') {
        notificationsStore.showNotification('error', $t('api.scripts.http.editScript.duplicateTitleError'))
      } else {
        notificationsStore.showNotification('error', $t('api.scripts.http.editScript.error'))
      }
    } finally {
      processing.value = false
    }

    return data
  }

  async function runScript(scriptId, transactionId) {
    const data = {}
    processingRun.value = true
    try {
      await scripts.runScript(scriptId, transactionId)
      data.response = true
    } catch (e) {
      data.error = e
      console.error('run script error', e)
      notificationsStore.showNotification('error', $t('api.scripts.http.runScript.error'))
    } finally {
      processingRun.value = false
    }

    return data
  }

  async function changeStatusScript({ scriptId, state }) {
    const data = {}
    let params = { scriptId, state }
    processing.value = true
    try {
      const response = await scripts.changeStatusScript(params)
      data.response = response.script
      notificationsStore.showNotification('success', $t('api.scripts.http.changeStatusScript.success'))
    } catch (e) {
      data.error = e
      console.error(e)
      notificationsStore.showNotification('error', $t('api.scripts.http.changeStatusScript.error'))
    } finally {
      processing.value = false
    }

    return data
  }

  async function deleteScript(scriptId) {
    const data = {}
    processing.value = true
    try {
      await scripts.deleteScript(scriptId)
      data.response = true
      notificationsStore.showNotification('success', $t('api.scripts.http.deleteScript.success'))
    } catch (e) {
      data.error = e
      console.error(e)
      notificationsStore.showNotification('error', $t('api.scripts.http.deleteScript.error'))
    } finally {
      processing.value = false
    }

    return data
  }

  async function importScripts({ files, scriptNames }) {
    const data = {}
    processing.value = true
    try {
      const response = await scripts.importScripts({
        projectId: $route.params.projectId,
        files,
        scriptNames
      })
      data.response = response.catalog
      notificationsStore.showNotification('success', $t('api.scripts.http.importScripts.success'))
    } catch (e) {
      data.error = e
      console.error(e)
      notificationsStore.showNotification('error', $t('api.scripts.http.importScripts.error'))
    } finally {
      processing.value = false
    }

    return data
  }

  async function exportScript(scriptId) {
    const data = {}
    processingExport.value = true
    try {
      const response = await scripts.exportScript(scriptId)
      data.response = response.file
      notificationsStore.showNotification('success', $t('api.scripts.http.exportScript.success'))
    } catch (e) {
      data.error = e
      console.error(e)
      notificationsStore.showNotification('error', $t('api.scripts.http.exportScript.error'))
    } finally {
      processingExport.value = false
    }

    return data
  }

  return {
    loading,
    processing,
    processingRun,
    processingExport,
    getScriptInfo,
    createScript,
    runScript,
    changeStatusScript,
    editScript,
    deleteScript,
    importScripts,
    exportScript
  }
}
