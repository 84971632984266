<template>
  <!-- eslint-disable-next-line vue/no-v-html -->
  <div :class="[$style.root, { loading }]" :style="{ width: `${width || size}px`, height: `${height || size}px` }" v-html="svg" />
</template>

<script setup>
import { computed, ref, watch } from 'vue'

const props = defineProps({
  name: {
    type: String,
    required: true
  },
  width: Number,
  height: Number,
  size: Number,
  /**
   * List of images that will be change by request
   */
  preload: Array
})

const loading = ref()
const loaded = ref(new Map())

const svg = computed(() => loaded.value.get(props.name))

watch(() => props.name, val => {
  if (!loaded.value.has(val)) {
    loadImages([val])
  }
})

init()

function init() {
  const imgList = props.preload?.length
    ? props.preload
    : [props.name]

  loading.value = true
  loadImages(imgList)
}

async function loadImages(imgList) {
  loading.value = true
  await Promise.all(
    imgList.map(
      icon => import(`@/../public/assets/icons/icn-${icon}.svg?raw`)
        .then(r => {
          loaded.value.set(icon, r.default)
        })
    )
  )
  loading.value = false
}
</script>

<style lang="scss" module>
.root {
  flex-shrink: 0;
  display: inline-flex;

  &:global(.loading) {
    visibility: hidden;
  }
}
</style>
