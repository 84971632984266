import { ref } from 'vue'
import { useRoute } from 'vue-router'
import { useI18n } from 'vue-i18n'

import { gateways } from '@/api'
import { useNotifications } from '@/stores'

export default function () {
  const { t: $t } = useI18n({ useScope: 'global' })
  const $route = useRoute()
  const notificationsStore = useNotifications()

  const loading = ref(false)
  const processing = ref(false)

  async function getGatewayClient() {
    const data = {}
    loading.value = true
    try {
      const response = await gateways.getGatewayClient($route.params.projectId)
      data.response = response.client
    } catch(e) {
      data.error = e
      notificationsStore.showNotification('error', $t('api.gateways.http.getGatewayClient.error'))
      console.error('gateway\'s request error')
    } finally {
      loading.value = false
    }

    return data
  }

  async function addGatewayClient({
    userName,
    password
  }) {
    const data = {}
    processing.value = true
    try {
      const response = await gateways.addGatewayClientAuth({
        projectId: $route.params.projectId,
        userName,
        password
      })
      data.response = response.client
      notificationsStore.showNotification('success', $t('api.gateways.http.addGatewayClient.success'))
    } catch(e) {
      data.error = e
      notificationsStore.showNotification('error', $t('api.gateways.http.addGatewayClient.error'))
      console.error('gateway\'s request error')
    } finally {
      processing.value = false
    }

    return data
  }

  async function editGatewayClient({
    userName,
    password
  }) {
    const data = {}
    processing.value = true
    try {
      const response = await gateways.editGatewayClientAuth({
        projectId: $route.params.projectId,
        userName,
        password
      })
      data.response = response.client
      notificationsStore.showNotification('success', $t('api.gateways.http.editGatewayClient.success'))
    } catch(e) {
      data.error = e
      notificationsStore.showNotification('error', $t('api.gateways.http.editGatewayClient.error'))
      console.error('gateway\'s request error')
    } finally {
      processing.value = false
    }

    return data
  }

  return {
    loading,
    processing,
    getGatewayClient,
    addGatewayClient,
    editGatewayClient
  }
}
