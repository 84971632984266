const TYPES = {
  VIRTUAL: 'VIRTUAL',
  MODBUS: 'MODBUS',
  OPCUA: 'OPCUA',
  MQTT: 'MQTT'
}

const TYPES_IN_URL = {
  [TYPES.VIRTUAL]: 'virtual',
  [TYPES.MODBUS]: 'modbus',
  [TYPES.OPCUA]: 'opcua',
  [TYPES.MQTT]: 'mqtt'
}

const TYPE_LIST = [
  TYPES.VIRTUAL,
  TYPES.MODBUS,
  TYPES.OPCUA,
  TYPES.MQTT
].map(type => ({
  id: type,
  title: `domains.tag.type.${type}`
}))

const DATA_TYPES = {
  BOOL: 'BOOL',
  SBYTE: 'SBYTE',
  BYTE: 'BYTE',
  INT16: 'INT16',
  UINT16: 'UINT16',
  INT32: 'INT32',
  UINT32: 'UINT32',
  INT64: 'INT64',
  FLOAT: 'FLOAT',
  DOUBLE: 'DOUBLE',
  DATETIME: 'DATETIME',
  STRING: 'STRING',
  BITMASK16: 'BITMASK16',
  BITMASK32: 'BITMASK32'
}

const DATA_TYPE_BYTE_WEIGHT = {
  [DATA_TYPES.BOOL]: 1,
  [DATA_TYPES.SBYTE]: 1,
  [DATA_TYPES.BYTE]: 1,
  [DATA_TYPES.INT16]: 2,
  [DATA_TYPES.UINT16]: 2,
  [DATA_TYPES.INT32]: 4,
  [DATA_TYPES.UINT32]: 4,
  [DATA_TYPES.INT64]: 8,
  [DATA_TYPES.FLOAT]: 4,
  [DATA_TYPES.DOUBLE]: 8,
  [DATA_TYPES.DATETIME]: 8,
  [DATA_TYPES.STRING]: 8,
  [DATA_TYPES.BITMASK16]: 0, // it weights less than 1 byte
  [DATA_TYPES.BITMASK32]: 0 // it weights less than 1 byte
}

// for all tag types except Modbus
const COMMON_DATA_TYPE_LIST = [
  DATA_TYPES.BOOL,
  DATA_TYPES.SBYTE,
  DATA_TYPES.BYTE,
  DATA_TYPES.INT16,
  DATA_TYPES.UINT16,
  DATA_TYPES.INT32,
  DATA_TYPES.UINT32,
  DATA_TYPES.INT64,
  DATA_TYPES.FLOAT,
  DATA_TYPES.DOUBLE,
  DATA_TYPES.DATETIME,
  DATA_TYPES.STRING
].map(dataType => ({
  id: dataType,
  title: `domains.tag.dataType.${dataType}`
}))
const MODBUS_DATA_TYPE_LIST = [
  DATA_TYPES.BOOL,
  DATA_TYPES.SBYTE,
  DATA_TYPES.BYTE,
  DATA_TYPES.INT16,
  DATA_TYPES.UINT16,
  DATA_TYPES.INT32,
  DATA_TYPES.UINT32,
  DATA_TYPES.FLOAT,
  DATA_TYPES.BITMASK16,
  DATA_TYPES.BITMASK32
].map(dataType => ({
  id: dataType,
  title: `domains.tag.dataType.${dataType}`
}))
const ALL_DATA_TYPE_LIST = COMMON_DATA_TYPE_LIST.concat([
  DATA_TYPES.BITMASK16, DATA_TYPES.BITMASK32
].map(dataType => ({
  id: dataType,
  title: `domains.tag.dataType.${dataType}`
})))

const READ_MODES = {
  READ_ONLY: 'READ_ONLY',
  WRITE_ONLY: 'WRITE_ONLY',
  READ_WRITE: 'READ_WRITE'
}

const READ_MODE_LIST = [
  READ_MODES.READ_ONLY,
  READ_MODES.WRITE_ONLY,
  READ_MODES.READ_WRITE
].map(mode => ({
  id: mode,
  title: `domains.tag.mode.${mode}`
}))

const EVENTS = {
  ALARM: 'ALARM',
  WARNING: 'WARNING',
  NORMAL: 'NORMAL'
}

const EVENT_LIST = [{
  id: EVENTS.ALARM,
  title: `domains.tag.event.${EVENTS.ALARM}`
}, {
  id: EVENTS.WARNING,
  title: `domains.tag.event.${EVENTS.WARNING}`
}]

const STATES = {
  ONLINE: 'ONLINE',
  OFFLINE: 'OFFLINE'
}

const STATE_LIST = [{
  id: STATES.ONLINE,
  title: `domains.tag.status.${STATES.ONLINE}`
}, {
  id: STATES.OFFLINE,
  title: `domains.tag.status.${STATES.OFFLINE}`
}]

const PROPERTIES = {
  CURRENT: 'CURRENT'
}

const STORAGE_TYPES = {
  NEVER: 'NEVER',
  CHANGE: 'CHANGE',
  CHANGE_BY_INTERVAL: 'CHANGE_BY_INTERVAL',
  COMBINED: 'COMBINED'
}

const STORAGE_TYPE_LIST = [
  STORAGE_TYPES.NEVER,
  STORAGE_TYPES.CHANGE,
  STORAGE_TYPES.CHANGE_BY_INTERVAL,
  STORAGE_TYPES.COMBINED
].map(type => ({
  id: type,
  title: `domains.tag.storage.${type}`
}))

const MODBUS_PROTOCOLS = {
  RTU: 'RTU',
  TCP_IP: 'TCP_IP'
}

const MODBUS_PROTOCOL_LIST = [
  {
    id: MODBUS_PROTOCOLS.RTU,
    title: 'ModBusRTU'
  },
  {
    id: MODBUS_PROTOCOLS.TCP_IP,
    title: 'ModBusTCP/IP'
  }
]

const MODBUS_REGISTER_TYPES = {
  COILS: 'COILS',
  DISCRETE_INPUTS: 'DISCRETE_INPUTS',
  HOLDING_REGISTERS: 'HOLDING_REGISTERS',
  INPUT_REGISTERS: 'INPUT_REGISTERS'
}

const MODBUS_REGISTER_TYPE_LIST = [{
  id: MODBUS_REGISTER_TYPES.COILS,
  title: 'Coils'
}, {
  id: MODBUS_REGISTER_TYPES.DISCRETE_INPUTS,
  title: 'Discrete inputs'
}, {
  id: MODBUS_REGISTER_TYPES.HOLDING_REGISTERS,
  title: 'Holding registers'
}, {
  id: MODBUS_REGISTER_TYPES.INPUT_REGISTERS,
  title: 'Input registers'
}]

const MODBUS_REGISTER_COMPATIBLE_DATA_TYPES = {
  [MODBUS_REGISTER_TYPES.COILS]: [DATA_TYPES.BOOL],
  [MODBUS_REGISTER_TYPES.DISCRETE_INPUTS]: [DATA_TYPES.BOOL],
  [MODBUS_REGISTER_TYPES.HOLDING_REGISTERS]: [
    DATA_TYPES.BYTE,
    DATA_TYPES.SBYTE,
    DATA_TYPES.INT16,
    DATA_TYPES.UINT16,
    DATA_TYPES.INT32,
    DATA_TYPES.UINT32,
    DATA_TYPES.FLOAT,
    DATA_TYPES.BITMASK16,
    DATA_TYPES.BITMASK32
  ],
  [MODBUS_REGISTER_TYPES.INPUT_REGISTERS]: [
    DATA_TYPES.BYTE,
    DATA_TYPES.SBYTE,
    DATA_TYPES.INT16,
    DATA_TYPES.UINT16,
    DATA_TYPES.INT32,
    DATA_TYPES.UINT32,
    DATA_TYPES.FLOAT,
    DATA_TYPES.BITMASK16,
    DATA_TYPES.BITMASK32
  ]
}

const MODBUS_REGISTER_COMPATIBLE_MODES = {
  [MODBUS_REGISTER_TYPES.COILS]: [READ_MODES.READ_ONLY, READ_MODES.WRITE_ONLY, READ_MODES.READ_WRITE],
  [MODBUS_REGISTER_TYPES.DISCRETE_INPUTS]: [READ_MODES.READ_ONLY],
  [MODBUS_REGISTER_TYPES.HOLDING_REGISTERS]: [READ_MODES.READ_ONLY, READ_MODES.WRITE_ONLY, READ_MODES.READ_WRITE],
  [MODBUS_REGISTER_TYPES.INPUT_REGISTERS]: [READ_MODES.READ_ONLY]
}

const BYTE_ENDIAN_TYPES = {
  BIG: 'BIG',
  LITTLE: 'LITTLE',
  MID_LITTLE: 'MID_LITTLE',
  MID_BIG: 'MID_BIG'
}

const BYTE_ENDIAN16_LIST = [{
  id: BYTE_ENDIAN_TYPES.BIG,
  title: '12(AB)'
},
{
  id: BYTE_ENDIAN_TYPES.LITTLE,
  title: '21(BA)'
}]

const BYTE_ENDIAN32_LIST = [{
  id: BYTE_ENDIAN_TYPES.BIG,
  title: '1234(ABCD)'
},
{
  id: BYTE_ENDIAN_TYPES.MID_BIG,
  title: '2143(BADC)'
},
{
  id: BYTE_ENDIAN_TYPES.MID_LITTLE,
  title: '3412(CDAB)'
},
{
  id: BYTE_ENDIAN_TYPES.LITTLE,
  title: '4321(DCBA)'
}]

const CATALOG_TYPES = {
  ...TYPES,
  NODE: 'NODE'
}

const CATALOG_TYPE_LIST = TYPE_LIST.concat({
  id: TYPES.NODE,
  title: 'blocks.tagNavigation.node'
})

const CREATE_SIZE_LIMIT = 30

export default {
  TYPES,
  TYPES_IN_URL,
  TYPE_LIST,
  DATA_TYPES,
  DATA_TYPE_BYTE_WEIGHT,
  COMMON_DATA_TYPE_LIST,
  MODBUS_DATA_TYPE_LIST,
  ALL_DATA_TYPE_LIST,
  BYTE_ENDIAN_TYPES,
  BYTE_ENDIAN16_LIST,
  BYTE_ENDIAN32_LIST,
  READ_MODES,
  READ_MODE_LIST,
  EVENTS,
  EVENT_LIST,
  STATES,
  STATE_LIST,
  PROPERTIES,
  STORAGE_TYPES,
  STORAGE_TYPE_LIST,
  MODBUS_PROTOCOLS,
  MODBUS_PROTOCOL_LIST,
  MODBUS_REGISTER_TYPES,
  MODBUS_REGISTER_TYPE_LIST,
  MODBUS_REGISTER_COMPATIBLE_DATA_TYPES,
  MODBUS_REGISTER_COMPATIBLE_MODES,
  CATALOG_TYPES,
  CATALOG_TYPE_LIST,
  CREATE_SIZE_LIMIT
}
