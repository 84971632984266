const FORM_OBSERVER_KEY = Symbol('form-observer')

const CONTROL_TYPES = {
  CHECKBOX: 'checkbox',
  INPUT: 'input',
  SELECT: 'select',
  SWITCH: 'switch',
  RADIO: 'radio'
}

const CONTROL_EVENTS = {
  CHANGE: 'change',
  BLUR: 'blur'
}

const INPUT_LENGTH_XS = 50
const INPUT_LENGTH_S = 500
const INPUT_LENGTH_M = 1000

export default {
  FORM_OBSERVER_KEY,
  CONTROL_TYPES,
  CONTROL_EVENTS,
  INPUT_LENGTH_XS,
  INPUT_LENGTH_S,
  INPUT_LENGTH_M
}
