<template>
  <div :class="$style.root">
    <AppNotification
      v-for="(el, index) in notificationsStore.notifications"
      :key="index"
      :type="el.type"
      :msg="el.msg"
      @click="notificationsStore.closeNotification(el.id)"
    />
  </div>
</template>

<script setup>
import { useNotifications } from '@/stores'
import AppNotification from '@/components/notification'

const notificationsStore = useNotifications()
</script>

<style lang="scss" module>
.root {
  position: fixed;
  top: #{20px + $header-height};
  right: 20px;
  width: 300px;
  z-index: $z-index-notification;
}
</style>
