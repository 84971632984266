import { ACCESS } from '@/common/constants'

const getResourceLevels = (access, resourceKey) => {
  const levelName = access[ACCESS.RESOURCES[resourceKey]]
  return {
    level: ACCESS.LEVELS[resourceKey][levelName],
    resourceLevels: ACCESS.LEVELS[resourceKey]
  }
}

const buildProjectAccessModel = access => {
  const { level, resourceLevels } = getResourceLevels(access, 'PROJECT')
  return {
    canAdd: level >= resourceLevels.UPDATE
  }
}

const buildUsersAccessModel = access => {
  const { level, resourceLevels } = getResourceLevels(access, 'USER')
  return {
    canRead: level >= resourceLevels.READ,
    canAdd: level >= resourceLevels.READ,
    canEdit: level >= resourceLevels.UPDATE_USER,
    canDelete: level >= resourceLevels.UPDATE_USER,
    canInteract: level >= resourceLevels.UPDATE_USER
  }
}

const buildUserGroupsAccessModel = access => {
  const { level, resourceLevels } = getResourceLevels(access, 'USER')
  return {
    canRead: level >= resourceLevels.UPDATE_USER,
    canAdd: level >= resourceLevels.UPDATE_GROUP,
    canEdit: level >= resourceLevels.UPDATE_GROUP,
    canDelete: level >= resourceLevels.UPDATE_GROUP
  }
}

const buildDashboardAccessModel = access => {
  const { level, resourceLevels } = getResourceLevels(access, 'DASHBOARD')
  return {
    canRead: level >= resourceLevels.READ,
    canAdd: level >= resourceLevels.UPDATE,
    canEdit: level >= resourceLevels.UPDATE,
    canDelete: level >= resourceLevels.UPDATE,
    canPin: level >= resourceLevels.INTERACTION,
    canInteract: level >= resourceLevels.INTERACTION,
    canExport: level >= resourceLevels.UPDATE,
    canImport: level >= resourceLevels.UPDATE
  }
}

const buildTagAccessModel = access => {
  const { level, resourceLevels } = getResourceLevels(access, 'TAG')
  return {
    canRead: level >= resourceLevels.READ,
    canAddTag: level >= resourceLevels.UPDATE_TAG,
    canAddNode: level >= resourceLevels.UPDATE_TAG,
    canEditTag: level >= resourceLevels.UPDATE_TAG,
    canEditNode: level >= resourceLevels.UPDATE_TAG,
    canDeleteTag: level >= resourceLevels.UPDATE_TAG,
    canDeleteNode: level >= resourceLevels.UPDATE_TAG,
    canReadTagTemplates: level >= resourceLevels.UPDATE_TAG_TEMPLATE,
    canAddTagTemplates: level >= resourceLevels.UPDATE_TAG_TEMPLATE,
    canEditTagTemplates: level >= resourceLevels.UPDATE_TAG_TEMPLATE,
    canDeleteTagTemplates: level >= resourceLevels.UPDATE_TAG_TEMPLATE
  }
}

const buildScaleAccessModel = access => {
  const { level, resourceLevels } = getResourceLevels(access, 'TAG')
  return {
    canAdd: level >= resourceLevels.UPDATE_TAG,
    canEdit: level >= resourceLevels.UPDATE_TAG,
    canDelete: level >= resourceLevels.UPDATE_TAG
  }
}

const buildGatewayAccessModel = access => {
  const { level, resourceLevels } = getResourceLevels(access, 'TAG')
  return {
    canRead: level >= resourceLevels.UPDATE_TAG,
    canAdd: level >= resourceLevels.UPDATE_TAG,
    canEdit: level >= resourceLevels.UPDATE_TAG,
    canDelete: level >= resourceLevels.UPDATE_TAG
  }
}


const buildMqttGatewayAccessModel = access => {
  const { level, resourceLevels } = getResourceLevels(access, 'TAG')
  return {
    canRead: level >= resourceLevels.UPDATE_TAG,
    canAdd: level >= resourceLevels.UPDATE_TAG,
    canEdit: level >= resourceLevels.UPDATE_TAG,
    canDelete: level >= resourceLevels.UPDATE_TAG
  }
}

const buildOpcServerAccessModel = access => {
  const { level, resourceLevels } = getResourceLevels(access, 'TAG')
  return{
    canRead: level >= resourceLevels.UPDATE_TAG,
    canAdd: level >= resourceLevels.UPDATE_TAG,
    canEdit: level >= resourceLevels.UPDATE_TAG,
    canDelete: level >= resourceLevels.UPDATE_TAG
  }
}

const buildScriptAccessModel = access => {
  const { level, resourceLevels } = getResourceLevels(access, 'SCRIPT')
  return {
    canRead: level >= resourceLevels.READ,
    canAddScript: level >= resourceLevels.UPDATE,
    canAddNode: level >= resourceLevels.UPDATE,
    canEditScript: level >= resourceLevels.UPDATE,
    canEditNode: level >= resourceLevels.UPDATE,
    canDeleteNode: level >= resourceLevels.UPDATE,
    canDeleteScript: level >= resourceLevels.UPDATE,
    canExportScript: level >= resourceLevels.UPDATE,
    canImportScript: level >= resourceLevels.UPDATE,
    canRunScript: level >= resourceLevels.UPDATE
  }
}

const buildNotificationAccessModel = access => {
  const { level, resourceLevels } = getResourceLevels(access, 'NOTIFICATION')
  return {
    canRead: level >= resourceLevels.READ,
    canEdit: level >= resourceLevels.READ,
    canExport: level >= resourceLevels.EXPORT
  }
}

const buildNotificationTemplateAccessModel = access => {
  const { level, resourceLevels } = getResourceLevels(access, 'NOTIFICATION')
  return {
    canRead: level >= resourceLevels.UPDATE_TEMPLATES,
    canAddUserTemplate: level >= resourceLevels.UPDATE_TEMPLATES,
    canEditUserTemplate: level >= resourceLevels.UPDATE_TEMPLATES,
    canDeleteUserTemplate: level >= resourceLevels.UPDATE_TEMPLATES,
    canEditSystemTemplate: level >= resourceLevels.UPDATE_SYSTEM_TEMPLATES
  }
}

const buildHistoryAccessModel = access => {
  const { level, resourceLevels } = getResourceLevels(access, 'HISTORY')
  return {
    canRead: level >= resourceLevels.READ,
    canExport: level >= resourceLevels.EXPORT
  }
}

const buildSettingsAccessModel = access => {
  const { level, resourceLevels } = getResourceLevels(access, 'PROJECT')
  return {
    canRead: level >= resourceLevels.NONE,
    canAdd: level >= resourceLevels.UPDATE,
    canEdit: level >= resourceLevels.UPDATE,
    canDelete: level >= resourceLevels.UPDATE
  }
}

export {
  // organization resources
  buildProjectAccessModel,
  buildUsersAccessModel,
  buildUserGroupsAccessModel,
  // project resources
  buildDashboardAccessModel,
  buildTagAccessModel,
  buildScaleAccessModel,
  buildGatewayAccessModel,
  buildMqttGatewayAccessModel,
  buildOpcServerAccessModel,
  buildScriptAccessModel,
  buildNotificationAccessModel,
  buildNotificationTemplateAccessModel,
  buildHistoryAccessModel,
  buildSettingsAccessModel
}
