import { ref } from 'vue'
import { useRoute } from 'vue-router'
import { useI18n } from 'vue-i18n'

import { ym } from '@/analytics'
import { dashboards } from '@/api'
import { API } from '@/common/constants'
import { useNotifications, useUser } from '@/stores'

export default function () {
  const { t: $t } = useI18n({ useScope: 'global' })
  const $route = useRoute()
  const userStore = useUser()
  const notificationsStore = useNotifications()

  const loadingDashboards = ref()
  const loadingWidgets = ref()
  const processingDashboards = ref()
  const processingWidgets = ref()
  const loadingDashboardsExport = ref()

  let widgetsController = new AbortController()

  async function getDashboards(projectId) {
    const data = {}
    loadingDashboards.value = true
    try {
      const response = await dashboards.getDashboards(projectId)
      data.response = response.dashboards
    } catch (e) {
      data.error = e
      notificationsStore.showNotification('error', $t('api.dashboards.http.getDashboards.error'))
      console.error('dashboards download error')
    } finally {
      loadingDashboards.value = false
    }

    return data
  }

  async function addDashboard({ title, widgets }) {
    const data = {}
    processingDashboards.value = true
    try {
      data.response = await dashboards.addDashboard({
        projectId: $route.params.projectId,
        title,
        widgets
      })
      notificationsStore.showNotification('success', $t('api.dashboards.http.addDashboard.success'))
      ym.sendDashboardCreate(userStore.profile.id, {
        projectId: $route.params.projectId,
        dashboardId: data.response.newDashboardId
      })
    } catch (e) {
      data.error = e
      if (e.code === API.COMMON_ERRORS.VALIDATION_FIELDS_ERROR) {
        notificationsStore.showNotification('error', $t('api.dashboards.http.addDashboard.errorValidation'))
      } else if (e.code === 'NOT_FOUND') {
        notificationsStore.showNotification('error', $t('api.dashboards.http.addDashboard.errorNotFound'))
      } else {
        notificationsStore.showNotification('error', $t('api.dashboards.http.addDashboard.error'))
      }
      console.error('error of dashboard adding', e)
    } finally {
      processingDashboards.value = false
    }

    return data
  }

  async function editDashboard({ dashboardId, title, widgets }) {
    const data = {}
    processingDashboards.value = true
    try {
      const response = await dashboards.editDashboard({
        title,
        widgets,
        dashboardId
      })
      data.response = response.version
      notificationsStore.showNotification('success', $t('api.dashboards.http.editDashboard.success'))
    } catch (e) {
      data.error = e
      notificationsStore.showNotification('error', $t('api.dashboards.http.editDashboard.error'))
      console.error('dashboard edit error', e)
    } finally {
      processingDashboards.value = false
    }

    return data
  }

  async function pinDashboard(dashboardId) {
    const data = {}
    processingDashboards.value = true
    try {
      const response = await dashboards.pinDashboard(dashboardId)
      data.response = response.dashboards
      notificationsStore.showNotification('success', $t('api.dashboards.http.pinDashboard.success'))
    } catch (e) {
      data.error = e
      notificationsStore.showNotification('error', $t('api.dashboards.http.pinDashboard.error'))
      console.error('dashboard pin operation error')
    } finally {
      processingDashboards.value = false
    }

    return data
  }

  async function exportDashboard(dashboardId) {
    const data = {}
    loadingDashboardsExport.value = true
    try {
      const response = await dashboards.exportDashboard(dashboardId)
      data.response = response.file
    } catch (e) {
      data.error = e
      notificationsStore.showNotification('error', $t('api.dashboards.http.exportDashboard.error'))
      console.error('dashboard information download error')
    } finally {
      loadingDashboardsExport.value = false
    }

    return data
  }

  async function deleteDashboard(dashboardId) {
    const data = {}
    processingDashboards.value = true
    try {
      await dashboards.deleteDashboard(dashboardId)
      data.response = true
      notificationsStore.showNotification('success', $t('api.dashboards.http.deleteDashboard.success'))
    } catch (e) {
      data.error = e
      notificationsStore.showNotification('error', $t('api.dashboards.http.deleteDashboard.error'))
      console.error('dashboard delete operation error')
    } finally {
      processingDashboards.value = false
    }

    return data
  }

  async function getDashboardWidgets(dashboardId) {
    if (loadingWidgets.value) {
      cancelWidgetsRequests()
    }

    let isAborted
    const data = {}
    loadingWidgets.value = true
    try {
      const response = await dashboards.getDashboardWidgets(widgetsController.signal)(dashboardId)
      data.response = response.widgets
    } catch (e) {
      if (!e.isAborted) {
        data.error = e
        notificationsStore.showNotification('error', $t('api.dashboards.http.getDashboardWidgets.error'))
        console.error('widgets download error')
      } else {
        isAborted = true
      }
    } finally {
      if (!isAborted) {
        loadingWidgets.value = false
      }
    }

    return data
  }

  async function importDashboard(file) {
    const data = {}
    loadingDashboards.value = true
    try {
      const response = await dashboards.importDashboard(file)
      data.response = response.widgets
    } catch (e) {
      data.error = e
      notificationsStore.showNotification('error', $t('api.dashboards.http.importDashboard.error'))
      console.error('import dashboard error', e)
    } finally {
      loadingDashboards.value = false
    }

    return data
  }

  async function updateWidgetValue(widgetId, { dataSource, value }) {
    const data = {}
    processingWidgets.value = true
    try {
      await dashboards.updateWidgetValue(widgetId, { dataSource, value })
      data.response = true
      notificationsStore.showNotification('success', $t('api.dashboards.http.updateWidgetValue.success'))
      ym.sendWidgetEdit(userStore.profile.id, {
        dashboardId: $route.params.dashboardId,
        widgetId
      })
    } catch (e) {
      data.error = e
      notificationsStore.showNotification('error', $t('api.dashboards.http.updateWidgetValue.error'))
      console.error('import dashboard error', e)
    } finally {
      processingWidgets.value = false
    }

    return data
  }

  function cancelWidgetsRequests() {
    widgetsController.abort()
    widgetsController = new AbortController()
  }

  return {
    loadingDashboards,
    loadingWidgets,
    loadingDashboardsExport,
    processingDashboards,
    processingWidgets,
    getDashboards,
    addDashboard,
    editDashboard,
    pinDashboard,
    exportDashboard,
    deleteDashboard,
    getDashboardWidgets,
    updateWidgetValue,
    importDashboard
  }
}
