import { createRouter, createWebHistory } from 'vue-router'
import { parse, stringify } from 'qs'

import { APP } from '@/common/constants'
import { useUser, useModals, useMain, useProjects } from '@/stores'
import routes from './routes'
import { hasAccessToRoute, hasAccessToCurrentProjectRoute, toNextProjectPage } from './utils'

const router = createRouter({
  routes: routes,
  history: createWebHistory(),
  parseQuery: params => parse(params, { comma: true }),
  stringifyQuery: params => stringify(params, {
    arrayFormat: 'comma',
    commaRoundTrip: true
  })
})

router.beforeEach(async (to, from) => {
  const modalStore = useModals()
  if (modalStore.modals.length) {
    modalStore.closeAllModals()
  }

  const userStore = useUser()
  const mainStore = useMain()
  if (userStore.hasSession() && (mainStore.isAuthInitialized || await mainStore.initAuth())) {
    const projectsStore = useProjects()
    if (
      !to.matched.length
      || to.params?.projectId && !projectsStore.hasProject(to.params.projectId)
      || to.name === APP.PAGES.AUTH
    ) {
      return { name: APP.PAGES.MAIN }
    } else if (!await hasAccessToRoute(to)) {
      if (to.params?.projectId && (to.name !== from.name || to.params.projectId !== from.params.projectId)) {
        return toNextProjectPage(to.params.projectId, to.name)
      } else if (from.name) {
        return false
      } else {
        return { name: APP.PAGES.MAIN }
      }
    }
  } else {
    if (!mainStore.isAnonInitialized) {
      mainStore.initAnon()
    }
    if (to.name !== APP.PAGES.AUTH) {
      return { name: APP.PAGES.AUTH }
    }
  }
})

export default router

export {
  hasAccessToRoute,
  hasAccessToCurrentProjectRoute
}
