import { sendEvent } from './instance'

function sendDashboardCreate(userId, {
  projectId,
  dashboardId
}) {
  sendEvent('DASHBOARD_CREATE', {
    user: {
      [userId]: {
        createDashboard: {
          [dashboardId]: {
            projectId
          }
        }
      }
    }
  })
}

function sendDashboardSwitch(userId, {
  initialId,
  destinationId,
  loadDuration
}) {
  sendEvent('DASHBOARD_SWITCH', {
    user: {
      [userId]: {
        moveFromDashboard: {
          [initialId]: {
            moveToDashboard: {
              [destinationId]: {
                loadDuration: loadDuration.toFixed(3)
              }
            }
          }
        }
      }
    }
  })
}

function sendWidgetEdit(userId, {
  dashboardId,
  widgetId
}) {
  sendEvent('WIDGET_EDIT', {
    user: {
      [userId]: {
        editWidget: {
          [widgetId]: {
            dashboardId
          }
        }
      }
    }
  })
}

function sendTagCreate(userId, {
  tagType,
  projectId
}) {
  sendEvent('TAG_CREATE', {
    user: {
      [userId]: {
        createTag: {
          [tagType]: {
            projectId
          }
        }
      }
    }
  })
}

export default {
  sendDashboardCreate,
  sendDashboardSwitch,
  sendWidgetEdit,
  sendTagCreate
}
