import http from '../instance'

const getDashboards = projectId =>
  http.get('dashboards', { params: { projectId } })

const addDashboard = ({ projectId, title, widgets }) =>
  http.post('/dashboards', { projectId, title, widgets })

const exportDashboard = dashboardId =>
  http.get(`/dashboards/${dashboardId}/export`, { responseType: 'blob' })

const deleteDashboard = dashboardId =>
  http.post(`/dashboards/${dashboardId}/delete`)

const pinDashboard = dashboardId =>
  http.post(`/dashboards/${dashboardId}/pin`)

const getDashboardWidgets = signal => dashboardId =>
  http.get(`/dashboards/${dashboardId}/widgets`, { signal })

const updateWidgetValue = (widgetId, { dataSource, value }) =>
  http.post(`/widgets/${widgetId}/value/edit`, { dataSource, value })

const importDashboard = file =>
  http.post('/dashboards/extract', { file }, { headers: { 'Content-Type': 'multipart/form-data' } })

export default {
  getDashboards,
  addDashboard,
  deleteDashboard,
  pinDashboard,
  exportDashboard,
  getDashboardWidgets,
  updateWidgetValue,
  importDashboard
}
