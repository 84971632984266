import { useRouter } from 'vue-router'

export default function (defaultRoute) {
  const router = useRouter()

  function back() {
    if (history.state.position > 1) {
      router.back()
    } else {
      router.push(defaultRoute || '/')
    }
  }

  return back
}
