import { ref } from 'vue'
import { useRoute } from 'vue-router'
import { useI18n } from 'vue-i18n'

import { gateways } from '@/api'
import { useModals, useNotifications } from '@/stores'

export default function () {
  const { t: $t } = useI18n({ useScope: 'global' })
  const $route = useRoute()
  const notificationsStore = useNotifications()
  const modalsStore = useModals()

  const loading = ref(false)
  const processing = ref(false)

  async function getGateways() {
    const data = {}
    loading.value = true
    try {
      data.response = await gateways.getGateways($route.params.projectId)
    } catch(e) {
      data.error = e
      notificationsStore.showNotification('error', $t('api.gateways.http.getGates.error'))
      console.error('gateway\'s request error')
    } finally {
      loading.value = false
    }

    return data
  }

  async function addGateway({ name, channel, serial, description }) {
    const data = {}
    processing.value = true
    try {
      const response = await gateways.addGateway({
        projectId: $route.params.projectId,
        name,
        channel,
        serial,
        description
      })
      data.response = response.gateways
      notificationsStore.showNotification('success', $t('api.gateways.http.addGate.success'))
    } catch(e) {
      data.error = e
      notificationsStore.showNotification('error', $t('api.gateways.http.addGate.error'))
      console.error('gateway save request error', e)
    } finally {
      processing.value = false
    }

    return data
  }

  async function editGateway(gatewayId, { name, channel, serial, description }) {
    const data = {}
    processing.value = true
    try {
      const response = await gateways.editGateway({
        gatewayId,
        name,
        channel,
        serial,
        description
      })
      data.response = response.gateways
      notificationsStore.showNotification('success', $t('api.gateways.http.addGate.success'))
    } catch(e) {
      data.error = e
      notificationsStore.showNotification('error', $t('api.gateways.http.addGate.error'))
      console.error('gateway save request error', e)
    } finally {
      processing.value = false
    }

    return data
  }

  async function deleteGateway(gatewayId) {
    const data = {}
    processing.value = true
    try {
      const response = await gateways.deleteGateway(gatewayId)
      data.response = response.gateways
      notificationsStore.showNotification('success', $t('api.gateways.http.deleteGate.success'))
    } catch(e) {
      if (e.code === 'DELETE_GATEWAY_WITH_TAG') {
        modalsStore.showModal({
          template: 'AppAlertModal',
          config: {
            title: $t('api.gateways.http.deleteGate.errorDeleteWithTagAlertModal.title'),
            description: $t('api.gateways.http.deleteGate.errorDeleteWithTagAlertModal.description'),
            btnText: $t('api.gateways.http.deleteGate.errorDeleteWithTagAlertModal.btnText')
          }
        })
      }
      notificationsStore.showNotification('error', $t('api.gateways.http.deleteGate.error'))
      console.error('delete gateway/s request error', e)
    } finally {
      processing.value = false
    }

    return data
  }

  return {
    loading,
    processing,
    getGateways,
    addGateway,
    editGateway,
    deleteGateway
  }
}
