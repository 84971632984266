import { inject, nextTick, onBeforeUnmount, provide, shallowRef } from 'vue'

export const PLACEMENTS = {
  LEFT: 'left',
  TOP: 'top',
  BOTTOM: 'bottom',
  RIGHT: 'right'
}

export const ALIGN = {
  START: 'start',
  CENTER: 'center',
  END: 'end'
}

const EVENTS = {
  CLOSE: 'close',
  UPDATED: 'updated'
}

const POSITION_CALCULATION_KEY = Symbol('use-position-calculation')

/**
 * @param toggle {import('vue').Ref<HTMLElement>} html reference on toggle
 * @param body {import('vue').Ref<HTMLElement>} html reference on body
 * @param options {Object} html reference on body
 * @param options.placement {String} preferred side
 * @param options.align {String} preferred align on a side
 * @param options.indent {Number} gap between toggle and reference
 * @param options.fitWidth {Boolean} should body have the same width with toggle
 * @param options.onlyOpposite {Boolean} should body be shown only on opposite side
 * @param options.onClose {Function} callback on close
 * @returns {{
 *   close: Function,
 *   open: Function,
 *   bodySettings: import('vue').Ref
 * }}
 */
export default function(toggle, body, {
  placement,
  align,
  indent,
  fitWidth,
  onlyOpposite,
  onClose
}) {
  provide(POSITION_CALCULATION_KEY, {
    subscribe,
    unsubscribe
  })

  const parentCalculator = inject(POSITION_CALCULATION_KEY)

  const bodySettings = shallowRef(null)
  const childPosCalcs = shallowRef([])
  const openCallback = shallowRef(null)
  let resizeTimeoutId

  const subscriptionIdentifier = parentCalculator?.subscribe(onParentCalcEvent)

  onBeforeUnmount(() => {
    close()
    clearTimeout(resizeTimeoutId)

    if (parentCalculator) {
      clearSubscriptions()
      unsubscribe(subscriptionIdentifier)
    }
  })

  const getMinWidth = () => {
    if (fitWidth && toggle.value) {
      return `width: ${toggle.value.offsetWidth}px`
    }
    return ''
  }

  const getDimensionParams = placement => ([PLACEMENTS.LEFT, PLACEMENTS.RIGHT].includes(placement)
    ? {
      side: PLACEMENTS.LEFT,
      secondSide: PLACEMENTS.RIGHT,
      size: 'width',
      altSize: 'height',
      altSide: PLACEMENTS.TOP,
      altSecondSide: PLACEMENTS.BOTTOM
    }
    : {
      side: PLACEMENTS.TOP,
      secondSide: PLACEMENTS.BOTTOM,
      size: 'height',
      altSize: 'width',
      altSide: PLACEMENTS.LEFT,
      altSecondSide: PLACEMENTS.RIGHT
    })

  const calcPosition = () => {
    const toggleRect = toggle.value.getBoundingClientRect()
    const bodyRect = body.value.getBoundingClientRect()
    const viewportSize = {
      width: window?.visualViewport?.width || document?.documentElement?.clientWidth,
      height: window?.visualViewport?.height || document?.documentElement?.clientHeight
    }
    const spaces = {
      top: toggleRect.top - indent,
      bottom: viewportSize.height - toggleRect.bottom - indent,
      left: toggleRect.left - indent,
      right: viewportSize.width - toggleRect.right - indent
    }
    const argNames = getDimensionParams(placement)

    let calculatedPlacement
    if (bodyRect[argNames.altSize] <= viewportSize[argNames.altSize]
      && spaces[placement] >= bodyRect[argNames.size]) {
      calculatedPlacement = placement
    } else {
      const maxVerticalSide = spaces.top > spaces.bottom
        ? PLACEMENTS.TOP
        : PLACEMENTS.BOTTOM
      const maxHorizontalSide = spaces.right > spaces.left
        ? PLACEMENTS.RIGHT
        : PLACEMENTS.LEFT
      if (onlyOpposite) {
        calculatedPlacement = [PLACEMENTS.RIGHT, PLACEMENTS.LEFT].includes(placement)
          ? maxHorizontalSide
          : maxVerticalSide
      } else {
        const maxVerticalArea = Math.min(bodyRect.height, spaces[maxVerticalSide])
          * Math.min(bodyRect.width, viewportSize.width)
        const maxHorizontalArea = Math.min(bodyRect.height, viewportSize.height)
          * Math.min(bodyRect.width, spaces[maxHorizontalSide])
        calculatedPlacement = maxVerticalArea >= maxHorizontalArea
          ? maxVerticalSide
          : maxHorizontalSide
      }
    }

    const position = calculatedPlacement === argNames.side
      ? toggleRect[argNames.side] - bodyRect[argNames.size] - indent
      : toggleRect[argNames.secondSide] + indent
    const defaultAltPosition = align === ALIGN.START
      ? toggleRect[argNames.altSide]
      : align === ALIGN.END
        ? toggleRect[argNames.altSide] + toggleRect[argNames.altSize] - bodyRect[argNames.altSize]
        : toggleRect[argNames.altSide] - (bodyRect[argNames.altSize] - toggleRect[argNames.altSize]) / 2
    const altPosition = viewportSize[argNames.altSize] - defaultAltPosition > bodyRect[argNames.altSize]
      ? Math.max(0, defaultAltPosition)
      : viewportSize[argNames.altSize] > bodyRect[argNames.altSize]
        ? viewportSize[argNames.altSize] - bodyRect[argNames.altSize]
        : 0
    const arrowPosition = toggleRect[argNames.altSide] + toggleRect[argNames.altSize] / 2

    return {
      calculatedPlacement,
      style: `${argNames.altSide}: ${altPosition}px; ${argNames.side}: ${position}px;${getMinWidth()}`,
      arrowStyle: arrowPosition < indent || arrowPosition > viewportSize[argNames.altSize] - indent
        ? 'display: none;'
        : `${argNames.altSize}: ${toggleRect[argNames.altSize]}px; ${argNames.altSide}: ${toggleRect[argNames.altSide] - altPosition}px;`
    }
  }

  const close = () => {
    onClose?.()
    window.removeEventListener('resize', onResize, { capture: true, passive: true })
    window.removeEventListener('scroll', onScroll, { capture: true, passive: true })
    bodySettings.value = null
    openCallback.value = null
  }

  function onResize() {
    close()
  }

  const onScroll = $event => {
    if (body.value && !$event.composedPath().includes(body.value)) {
      close()
    }
  }

  function onOpen(afterOpen = () => {}) {
    if (!body.value) { // wait until body will render
      openCallback.value = () => open(afterOpen)
      setTimeout(() => {
        openCallback.value?.()
      })
    } else if (parentCalculator) {
      openCallback.value = () => open(afterOpen)
    } else {
      open(afterOpen)
    }
  }

  function onParentCalcEvent(event) {
    if (event === EVENTS.UPDATED) {
      if (!bodySettings.value) {
        openCallback.value?.()
      }
    } else if (event === EVENTS.CLOSE) {
      close()
    }
  }

  function open(callback) {
    if (toggle.value) {
      bodySettings.value = {
        placement,
        style: 'top: -10000px; left: -10000px; visibility: hidden;' + getMinWidth()
      }
      nextTick(() => {
        bodySettings.value = calcPosition()
        window.addEventListener('scroll', onScroll, { capture: true, passive: true })
        window.addEventListener('resize', onResize, { capture: true, passive: true })
        setTimeout(() => { // wait until body will rerender
          notify(EVENTS.UPDATED)
          callback()
        })
      })
    }
  }

  function subscribe(callback) {
    let identifier
    if (typeof callback === 'function') {
      childPosCalcs.value = childPosCalcs.value.concat(callback)
      identifier = childPosCalcs.value.length - 1
    }

    return identifier
  }

  function unsubscribe(identifier) {
    if (Number.isInteger(identifier)) {
      childPosCalcs.value = childPosCalcs.value
        .slice(0, identifier)
        .concat(identifier + 1)

      return true
    }

    return false
  }

  function notify(event) {
    childPosCalcs.value.forEach(cb => {
      cb(event)
    })
  }

  function clearSubscriptions() {
    notify(EVENTS.CLOSE)
    childPosCalcs.value = []
  }

  return {
    bodySettings,
    open: onOpen,
    close
  }
}
